import InputChat from "./InputChat";
import { Box, useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import useProperNounsSettings from "hooks/useProperNounsSettings";

function Chat() {
  const { setInitialProperNounsSettings } = useProperNounsSettings();

  useEffect(() => {
    setInitialProperNounsSettings();
  }, []);
  return (
    <Box
      id="main_input"
      sx={{
        maxWidth: "640px",
        alignItems: "center",
        alignSelf: "stretch",
        margin: (theme) =>
          useMediaQuery(theme.breakpoints.up("md"))
            ? theme.spacing(10)
            : theme.spacing(5),
      }}
    >
      <InputChat />
    </Box>
  );
}

export default Chat;
