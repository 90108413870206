import { useEffect } from "react";
import Box from "@mui/material/Box";
import useWordRegister from "../../hooks/useWordRegister";
import { Button } from "@mui/material";
import RegisteredWordList from "./RegisteredWordList";
import { useSnackbar } from "notistack";
import { readCSV } from "../../helper/fileHelper";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const successSaveProfiledWordsMessage = "固有名詞を保存しました。";
const successBulkAddProfiledWordsMessage =
  "ファイルの内容をテキストフィールドに反映しました。問題なければ保存ボタンを押してください。";
const failedReadCSVMessage = "CSVファイルの読み込みに失敗しました。";

export default function WordRegistration() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const {
    profiledWordsFileds,
    registerProfiledWords,
    registerProfiledWordsErrors,
    addProfiledWords,
    updateProfiledWords,
    removeProfiledWords,
    saveProfiledWords,
    submitProfiledWords,
    bulkAddProfiledWords,
    loadProfiledWords,
    personalRegisteredWords,
  } = useWordRegister();

  useEffect(() => {
    (async () => {
      await loadProfiledWords();
    })();
  }, []);

  const navigate = useNavigate();

  function moveToEdit(index, word, reading) {
    navigate(`/edit-word`, {
      state: { index: index, word: word, reading: reading },
    });
  }

  const handleSubmitProfiledWords = submitProfiledWords(async (data) => {
    await saveProfiledWords(data.profiledWords);
    enqueueSnackbar(successSaveProfiledWordsMessage, { variant: "success" });
  });

  const handleInputCSV = async (file) => {
    try {
      const res = await readCSV(file, true);
      const words = res.map((item) => {
        return { word: item[0], reading: item[1] };
      });
      bulkAddProfiledWords(words); // TODO: 保存まで一括で完了させる
      enqueueSnackbar(successBulkAddProfiledWordsMessage, {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(`${failedReadCSVMessage}${e.message}`, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          mt: { xs: theme.spacing(5), md: theme.spacing(10) },
        }}
      >
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <Button
            type="submit"
            variant="text"
            color="primary"
            component={RouterLink}
            to="/register-new-word"
            size="small"
            sx={{
              position: "fixed",
              zIndex: "10000",
              top: "0.4rem",
              right: "0",
            }}
          >
            追加
          </Button>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            width: { md: "640px" },
            marginX: { md: "auto" },
          }}
        >
          <RegisteredWordList
            profiledWordsFileds={profiledWordsFileds}
            registerProfiledWords={registerProfiledWords}
            addWord={addProfiledWords}
            onChangeWord={updateProfiledWords}
            removeWord={removeProfiledWords}
            onInputChange={updateProfiledWords}
            handleSubmit={handleSubmitProfiledWords}
            registerProfiledWordsErrors={registerProfiledWordsErrors}
            onInputCSV={handleInputCSV}
            personalRegisteredWords={personalRegisteredWords}
            showBulkRegistration={false} // 一括登録を一時的に非表示にする
            moveToEdit={moveToEdit}
          />
        </Box>
        <Button
          id="goBackToProperNounList"
          variant="text"
          size="small"
          sx={{ mt: "1.25rem", height: "0.875rem", color: "primary" }}
          onClick={() => navigate("/proper-nouns")}
        >
          戻る
        </Button>
      </Box>
    </>
  );
}
