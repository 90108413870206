import { useState } from "react";
import { CheckboxField } from "@aws-amplify/ui-react";
import PropTypes from "prop-types";
import { Link, Box } from "@mui/material";

export const Checkbox = ({ validationErrors }) => {
  const [touched, setTouched] = useState(false);

  return (
    <CheckboxField
      errorMessage={validationErrors.acknowledgement}
      hasError={touched && !!validationErrors.acknowledgement}
      name="acknowledgement"
      value="yes"
      onChange={() => setTouched(true)}
      label={
        <Box sx={{ pl: "4px" }}>
          <p>
            <Link
              href="/terms_of_service.html"
              target="_blank"
              rel="noreferrer"
            >
              利用規約
            </Link>{" "}
            /{" "}
            <Link href="/privacy_policy.html" target="_blank" rel="noreferrer">
              プライバシーポリシー
            </Link>{" "}
            /{" "}
            <Link href="/data_policy.pdf" target="_blank" rel="noreferrer">
              データポリシー
            </Link>
            に同意する
          </p>
        </Box>
      }
    />
  );
};

Checkbox.propTypes = {
  validationErrors: PropTypes.object,
};
