import * as React from "react";
import { ListItemText, ListItemButton, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PropTypes from "prop-types";

export const MainListItems = ({ onClickMenu }) => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <ListItemButton
        divider
        onClick={() => {
          navigate("/dashboard");
          onClickMenu();
        }}
      >
        <ListItemText primary="報告入力" />
        <IconButton aria-label="edit" sx={{ p: 0 }}>
          <ArrowForwardIosIcon sx={{ width: "14px" }} />
        </IconButton>
      </ListItemButton>
      <ListItemButton
        divider
        onClick={() => {
          navigate("/proper-nouns");
          onClickMenu();
        }}
      >
        <ListItemText primary="固有名詞の登録" />
        <IconButton aria-label="edit" sx={{ p: 0 }}>
          <ArrowForwardIosIcon sx={{ width: "14px" }} />
        </IconButton>
      </ListItemButton>
      {/* TODO: 個社特別対応 */}
      {/* <ListItemButton
        divider
        onClick={() => {
          navigate("/usage-situation");
          onClickMenu();
        }}
      >
        <ListItemText primary="利用状況" />
        <IconButton aria-label="edit" sx={{ p: 0 }}>
          <ArrowForwardIosIcon sx={{ width: "14px" }} />
        </IconButton>
      </ListItemButton> */}
      <ListItemButton
        divider
        onClick={() => window.open("/manual.pdf", "_blank")}
      >
        <ListItemText primary="マニュアル" />
        <IconButton aria-label="edit" sx={{ p: 0 }}>
          <ArrowForwardIosIcon sx={{ width: "14px" }} />
        </IconButton>
      </ListItemButton>
      <ListItemButton
        divider
        onClick={() => window.open("/privacy_policy.html", "_blank")}
      >
        <ListItemText primary="プライバシーポリシー" />
        <IconButton aria-label="edit" sx={{ p: 0 }}>
          <ArrowForwardIosIcon sx={{ width: "14px" }} />
        </IconButton>
      </ListItemButton>
      <ListItemButton
        divider
        onClick={() => window.open("/terms_of_service.html", "_blank")}
      >
        <ListItemText primary="利用規約" />
        <IconButton aria-label="edit" sx={{ p: 0 }}>
          <ArrowForwardIosIcon sx={{ width: "14px" }} />
        </IconButton>
      </ListItemButton>
      <ListItemButton
        divider
        onClick={() => window.open("/data_policy.pdf", "_blank")}
      >
        <ListItemText primary="データポリシー" />
        <IconButton aria-label="edit" sx={{ p: 0 }}>
          <ArrowForwardIosIcon sx={{ width: "14px" }} />
        </IconButton>
      </ListItemButton>
    </React.Fragment>
  );
};

MainListItems.propTypes = {
  onClickMenu: PropTypes.func,
};
