/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateRequest = /* GraphQL */ `
  subscription OnCreateRequest(
    $filter: ModelSubscriptionRequestFilterInput
    $owner: String
  ) {
    onCreateRequest(filter: $filter, owner: $owner) {
      id
      message
      response
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateRequest = /* GraphQL */ `
  subscription OnUpdateRequest(
    $filter: ModelSubscriptionRequestFilterInput
    $owner: String
  ) {
    onUpdateRequest(filter: $filter, owner: $owner) {
      id
      message
      response
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteRequest = /* GraphQL */ `
  subscription OnDeleteRequest(
    $filter: ModelSubscriptionRequestFilterInput
    $owner: String
  ) {
    onDeleteRequest(filter: $filter, owner: $owner) {
      id
      message
      response
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePlanMaster = /* GraphQL */ `
  subscription OnCreatePlanMaster(
    $filter: ModelSubscriptionPlanMasterFilterInput
  ) {
    onCreatePlanMaster(filter: $filter) {
      planId
      planName
      maxSummarizeTextCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePlanMaster = /* GraphQL */ `
  subscription OnUpdatePlanMaster(
    $filter: ModelSubscriptionPlanMasterFilterInput
  ) {
    onUpdatePlanMaster(filter: $filter) {
      planId
      planName
      maxSummarizeTextCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePlanMaster = /* GraphQL */ `
  subscription OnDeletePlanMaster(
    $filter: ModelSubscriptionPlanMasterFilterInput
  ) {
    onDeletePlanMaster(filter: $filter) {
      planId
      planName
      maxSummarizeTextCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTenantUnderContractPlan = /* GraphQL */ `
  subscription OnCreateTenantUnderContractPlan(
    $filter: ModelSubscriptionTenantUnderContractPlanFilterInput
  ) {
    onCreateTenantUnderContractPlan(filter: $filter) {
      id
      planId {
        planId
        planName
        maxSummarizeTextCount
        createdAt
        updatedAt
        __typename
      }
      appliedStatus
      contractStartDate
      contractEndDate
      tenantId
      monthlyTextCountResetDate
      createdAt
      updatedAt
      tenantUnderContractPlanPlanIdPlanId
      __typename
    }
  }
`;
export const onUpdateTenantUnderContractPlan = /* GraphQL */ `
  subscription OnUpdateTenantUnderContractPlan(
    $filter: ModelSubscriptionTenantUnderContractPlanFilterInput
  ) {
    onUpdateTenantUnderContractPlan(filter: $filter) {
      id
      planId {
        planId
        planName
        maxSummarizeTextCount
        createdAt
        updatedAt
        __typename
      }
      appliedStatus
      contractStartDate
      contractEndDate
      tenantId
      monthlyTextCountResetDate
      createdAt
      updatedAt
      tenantUnderContractPlanPlanIdPlanId
      __typename
    }
  }
`;
export const onDeleteTenantUnderContractPlan = /* GraphQL */ `
  subscription OnDeleteTenantUnderContractPlan(
    $filter: ModelSubscriptionTenantUnderContractPlanFilterInput
  ) {
    onDeleteTenantUnderContractPlan(filter: $filter) {
      id
      planId {
        planId
        planName
        maxSummarizeTextCount
        createdAt
        updatedAt
        __typename
      }
      appliedStatus
      contractStartDate
      contractEndDate
      tenantId
      monthlyTextCountResetDate
      createdAt
      updatedAt
      tenantUnderContractPlanPlanIdPlanId
      __typename
    }
  }
`;
export const onCreateOptionSettings = /* GraphQL */ `
  subscription OnCreateOptionSettings(
    $filter: ModelSubscriptionOptionSettingsFilterInput
  ) {
    onCreateOptionSettings(filter: $filter) {
      id
      custome_message_enabled
      custome_message_contents
      file_upload_enabled
      multi_language_enabled
      tenant_id
      __typename
    }
  }
`;
export const onUpdateOptionSettings = /* GraphQL */ `
  subscription OnUpdateOptionSettings(
    $filter: ModelSubscriptionOptionSettingsFilterInput
  ) {
    onUpdateOptionSettings(filter: $filter) {
      id
      custome_message_enabled
      custome_message_contents
      file_upload_enabled
      multi_language_enabled
      tenant_id
      __typename
    }
  }
`;
export const onDeleteOptionSettings = /* GraphQL */ `
  subscription OnDeleteOptionSettings(
    $filter: ModelSubscriptionOptionSettingsFilterInput
  ) {
    onDeleteOptionSettings(filter: $filter) {
      id
      custome_message_enabled
      custome_message_contents
      file_upload_enabled
      multi_language_enabled
      tenant_id
      __typename
    }
  }
`;
export const onCreateAzureOAIEndpointPaths = /* GraphQL */ `
  subscription OnCreateAzureOAIEndpointPaths(
    $filter: ModelSubscriptionAzureOAIEndpointPathsFilterInput
  ) {
    onCreateAzureOAIEndpointPaths(filter: $filter) {
      id
      azureOpenAIEndpoint
      relayRequestAzureApiUrl
      relayRequestAzureApikey
      __typename
    }
  }
`;
export const onUpdateAzureOAIEndpointPaths = /* GraphQL */ `
  subscription OnUpdateAzureOAIEndpointPaths(
    $filter: ModelSubscriptionAzureOAIEndpointPathsFilterInput
  ) {
    onUpdateAzureOAIEndpointPaths(filter: $filter) {
      id
      azureOpenAIEndpoint
      relayRequestAzureApiUrl
      relayRequestAzureApikey
      __typename
    }
  }
`;
export const onDeleteAzureOAIEndpointPaths = /* GraphQL */ `
  subscription OnDeleteAzureOAIEndpointPaths(
    $filter: ModelSubscriptionAzureOAIEndpointPathsFilterInput
  ) {
    onDeleteAzureOAIEndpointPaths(filter: $filter) {
      id
      azureOpenAIEndpoint
      relayRequestAzureApiUrl
      relayRequestAzureApikey
      __typename
    }
  }
`;
export const onCreateCorporateProperNoun = /* GraphQL */ `
  subscription OnCreateCorporateProperNoun(
    $filter: ModelSubscriptionCorporateProperNounFilterInput
  ) {
    onCreateCorporateProperNoun(filter: $filter) {
      id
      writing
      reading
      categoryId
      tenant_id
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCorporateProperNoun = /* GraphQL */ `
  subscription OnUpdateCorporateProperNoun(
    $filter: ModelSubscriptionCorporateProperNounFilterInput
  ) {
    onUpdateCorporateProperNoun(filter: $filter) {
      id
      writing
      reading
      categoryId
      tenant_id
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCorporateProperNoun = /* GraphQL */ `
  subscription OnDeleteCorporateProperNoun(
    $filter: ModelSubscriptionCorporateProperNounFilterInput
  ) {
    onDeleteCorporateProperNoun(filter: $filter) {
      id
      writing
      reading
      categoryId
      tenant_id
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCorporateProperNounCategory = /* GraphQL */ `
  subscription OnCreateCorporateProperNounCategory(
    $filter: ModelSubscriptionCorporateProperNounCategoryFilterInput
  ) {
    onCreateCorporateProperNounCategory(filter: $filter) {
      id
      sortOrder
      name
      tenant_id
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCorporateProperNounCategory = /* GraphQL */ `
  subscription OnUpdateCorporateProperNounCategory(
    $filter: ModelSubscriptionCorporateProperNounCategoryFilterInput
  ) {
    onUpdateCorporateProperNounCategory(filter: $filter) {
      id
      sortOrder
      name
      tenant_id
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCorporateProperNounCategory = /* GraphQL */ `
  subscription OnDeleteCorporateProperNounCategory(
    $filter: ModelSubscriptionCorporateProperNounCategoryFilterInput
  ) {
    onDeleteCorporateProperNounCategory(filter: $filter) {
      id
      sortOrder
      name
      tenant_id
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTenantSummarizedTextCount = /* GraphQL */ `
  subscription OnCreateTenantSummarizedTextCount(
    $filter: ModelSubscriptionTenantSummarizedTextCountFilterInput
  ) {
    onCreateTenantSummarizedTextCount(filter: $filter) {
      summaryStartDateTime
      textCount
      userId
      summaryMode
      inputLang
      outputLang
      tenantId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTenantSummarizedTextCount = /* GraphQL */ `
  subscription OnUpdateTenantSummarizedTextCount(
    $filter: ModelSubscriptionTenantSummarizedTextCountFilterInput
  ) {
    onUpdateTenantSummarizedTextCount(filter: $filter) {
      summaryStartDateTime
      textCount
      userId
      summaryMode
      inputLang
      outputLang
      tenantId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTenantSummarizedTextCount = /* GraphQL */ `
  subscription OnDeleteTenantSummarizedTextCount(
    $filter: ModelSubscriptionTenantSummarizedTextCountFilterInput
  ) {
    onDeleteTenantSummarizedTextCount(filter: $filter) {
      summaryStartDateTime
      textCount
      userId
      summaryMode
      inputLang
      outputLang
      tenantId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTenantVoiceInputTextCount = /* GraphQL */ `
  subscription OnCreateTenantVoiceInputTextCount(
    $filter: ModelSubscriptionTenantVoiceInputTextCountFilterInput
  ) {
    onCreateTenantVoiceInputTextCount(filter: $filter) {
      voiceInputStartDateTime
      textCount
      inputDuration
      userId
      summaryMode
      tenantId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTenantVoiceInputTextCount = /* GraphQL */ `
  subscription OnUpdateTenantVoiceInputTextCount(
    $filter: ModelSubscriptionTenantVoiceInputTextCountFilterInput
  ) {
    onUpdateTenantVoiceInputTextCount(filter: $filter) {
      voiceInputStartDateTime
      textCount
      inputDuration
      userId
      summaryMode
      tenantId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTenantVoiceInputTextCount = /* GraphQL */ `
  subscription OnDeleteTenantVoiceInputTextCount(
    $filter: ModelSubscriptionTenantVoiceInputTextCountFilterInput
  ) {
    onDeleteTenantVoiceInputTextCount(filter: $filter) {
      voiceInputStartDateTime
      textCount
      inputDuration
      userId
      summaryMode
      tenantId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateMonthlyTenantProcessedTextCount = /* GraphQL */ `
  subscription OnCreateMonthlyTenantProcessedTextCount(
    $filter: ModelSubscriptionMonthlyTenantProcessedTextCountFilterInput
  ) {
    onCreateMonthlyTenantProcessedTextCount(filter: $filter) {
      id
      planId {
        planId
        planName
        maxSummarizeTextCount
        createdAt
        updatedAt
        __typename
      }
      activatedStatus
      monthlyTextCount
      monthlyTextCountStartDate
      tenantId
      createdAt
      updatedAt
      monthlyTenantProcessedTextCountPlanIdPlanId
      __typename
    }
  }
`;
export const onUpdateMonthlyTenantProcessedTextCount = /* GraphQL */ `
  subscription OnUpdateMonthlyTenantProcessedTextCount(
    $filter: ModelSubscriptionMonthlyTenantProcessedTextCountFilterInput
  ) {
    onUpdateMonthlyTenantProcessedTextCount(filter: $filter) {
      id
      planId {
        planId
        planName
        maxSummarizeTextCount
        createdAt
        updatedAt
        __typename
      }
      activatedStatus
      monthlyTextCount
      monthlyTextCountStartDate
      tenantId
      createdAt
      updatedAt
      monthlyTenantProcessedTextCountPlanIdPlanId
      __typename
    }
  }
`;
export const onDeleteMonthlyTenantProcessedTextCount = /* GraphQL */ `
  subscription OnDeleteMonthlyTenantProcessedTextCount(
    $filter: ModelSubscriptionMonthlyTenantProcessedTextCountFilterInput
  ) {
    onDeleteMonthlyTenantProcessedTextCount(filter: $filter) {
      id
      planId {
        planId
        planName
        maxSummarizeTextCount
        createdAt
        updatedAt
        __typename
      }
      activatedStatus
      monthlyTextCount
      monthlyTextCountStartDate
      tenantId
      createdAt
      updatedAt
      monthlyTenantProcessedTextCountPlanIdPlanId
      __typename
    }
  }
`;
export const onCreateMonthlyVoiceRecognitionUsage = /* GraphQL */ `
  subscription OnCreateMonthlyVoiceRecognitionUsage(
    $filter: ModelSubscriptionMonthlyVoiceRecognitionUsageFilterInput
    $owner: String
  ) {
    onCreateMonthlyVoiceRecognitionUsage(filter: $filter, owner: $owner) {
      userId
      year
      month
      usageTime
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateMonthlyVoiceRecognitionUsage = /* GraphQL */ `
  subscription OnUpdateMonthlyVoiceRecognitionUsage(
    $filter: ModelSubscriptionMonthlyVoiceRecognitionUsageFilterInput
    $owner: String
  ) {
    onUpdateMonthlyVoiceRecognitionUsage(filter: $filter, owner: $owner) {
      userId
      year
      month
      usageTime
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteMonthlyVoiceRecognitionUsage = /* GraphQL */ `
  subscription OnDeleteMonthlyVoiceRecognitionUsage(
    $filter: ModelSubscriptionMonthlyVoiceRecognitionUsageFilterInput
    $owner: String
  ) {
    onDeleteMonthlyVoiceRecognitionUsage(filter: $filter, owner: $owner) {
      userId
      year
      month
      usageTime
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateVoiceFileUploadRequest = /* GraphQL */ `
  subscription OnCreateVoiceFileUploadRequest(
    $filter: ModelSubscriptionVoiceFileUploadRequestFilterInput
    $owner: String
  ) {
    onCreateVoiceFileUploadRequest(filter: $filter, owner: $owner) {
      id
      sessionId
      filename
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateVoiceFileUploadRequest = /* GraphQL */ `
  subscription OnUpdateVoiceFileUploadRequest(
    $filter: ModelSubscriptionVoiceFileUploadRequestFilterInput
    $owner: String
  ) {
    onUpdateVoiceFileUploadRequest(filter: $filter, owner: $owner) {
      id
      sessionId
      filename
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteVoiceFileUploadRequest = /* GraphQL */ `
  subscription OnDeleteVoiceFileUploadRequest(
    $filter: ModelSubscriptionVoiceFileUploadRequestFilterInput
    $owner: String
  ) {
    onDeleteVoiceFileUploadRequest(filter: $filter, owner: $owner) {
      id
      sessionId
      filename
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCustomPromptMenu = /* GraphQL */ `
  subscription OnCreateCustomPromptMenu(
    $filter: ModelSubscriptionCustomPromptMenuFilterInput
  ) {
    onCreateCustomPromptMenu(filter: $filter) {
      id
      tenantId
      sortOrder
      name
      promptId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCustomPromptMenu = /* GraphQL */ `
  subscription OnUpdateCustomPromptMenu(
    $filter: ModelSubscriptionCustomPromptMenuFilterInput
  ) {
    onUpdateCustomPromptMenu(filter: $filter) {
      id
      tenantId
      sortOrder
      name
      promptId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCustomPromptMenu = /* GraphQL */ `
  subscription OnDeleteCustomPromptMenu(
    $filter: ModelSubscriptionCustomPromptMenuFilterInput
  ) {
    onDeleteCustomPromptMenu(filter: $filter) {
      id
      tenantId
      sortOrder
      name
      promptId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCustomPrompt = /* GraphQL */ `
  subscription OnCreateCustomPrompt(
    $filter: ModelSubscriptionCustomPromptFilterInput
  ) {
    onCreateCustomPrompt(filter: $filter) {
      id
      summaryPromptSystemRole
      summaryPromptUserRole
      mergePromptSystemRole
      mergePromptUserRole
      mergePattern
      chunkSize
      chunkOverlap
      inputMaxLength
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCustomPrompt = /* GraphQL */ `
  subscription OnUpdateCustomPrompt(
    $filter: ModelSubscriptionCustomPromptFilterInput
  ) {
    onUpdateCustomPrompt(filter: $filter) {
      id
      summaryPromptSystemRole
      summaryPromptUserRole
      mergePromptSystemRole
      mergePromptUserRole
      mergePattern
      chunkSize
      chunkOverlap
      inputMaxLength
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCustomPrompt = /* GraphQL */ `
  subscription OnDeleteCustomPrompt(
    $filter: ModelSubscriptionCustomPromptFilterInput
  ) {
    onDeleteCustomPrompt(filter: $filter) {
      id
      summaryPromptSystemRole
      summaryPromptUserRole
      mergePromptSystemRole
      mergePromptUserRole
      mergePattern
      chunkSize
      chunkOverlap
      inputMaxLength
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onSendRequestId = /* GraphQL */ `
  subscription OnSendRequestId($id: ID) {
    onSendRequestId(id: $id) {
      id
      message
      response
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
