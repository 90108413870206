export const summaryModeOptions = {
  normal: { value: "normal", label: "普通モード" },
  meeting: { value: "meeting", label: "会議モード" },
  meetingSpeakerRecognition: {
    value: "meetingSpeakerRecognition",
    label: "会議モード（話者自動認識）",
  },
};

export const customPromptModePrefix = "customPrompt_";
