/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_mobile_analytics_app_id": "a4a2aa3df1304edd8288d0c34f37d498",
    "aws_mobile_analytics_app_region": "ap-northeast-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "a4a2aa3df1304edd8288d0c34f37d498",
            "region": "ap-northeast-1"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://vv6i3zcvu5e25i2xnm463k4kgq.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "ap-northeast-1:9eef14b6-bcc8-4896-b0cc-89780ee4b8ca",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_sGRMzHRKr",
    "aws_user_pools_web_client_id": "4oecth4trm8rkpffagq9ml9rp9",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "samplegraphql-20230418224859-hostingbucket-stg",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "http://samplegraphql-20230418224859-hostingbucket-stg.s3-website-ap-northeast-1.amazonaws.com",
    "aws_dynamodb_all_tables_region": "ap-northeast-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "tenant-stg",
            "region": "ap-northeast-1"
        }
    ]
};


export default awsmobile;
