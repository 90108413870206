import { useState, useEffect, useRef, useContext } from "react";
import React from "react";
import { Grid, Typography, Box, CircularProgress } from "@mui/material";
import { ProcessingProgress } from "./ProcessingProgress";
import { chat } from "../chat";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { copyTextToClipboard } from "../helper/copyToClipboard";
import { downloadText } from "../helper/fileHelper";
import { useSnackbar } from "notistack";
import { TenantCustomMessage } from "./TenantCustomMessage";
import {
  scrollMUIMainComponent,
  scrollTextareaToCaret,
} from "../helper/scrollHelper";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ClearIcon from "@mui/icons-material/Clear";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useVoiceRecognition from "../helper/useVoiceRecognition";
import CloseIcon from "@mui/icons-material/Close";
import MicIcon from "@mui/icons-material/Mic";
import IosShareIcon from "@mui/icons-material/IosShare";
import { isIphone } from "../helper/deviceHelper";
import PropTypes from "prop-types";
import SummaryModeSelect from "./SummaryModeSelect";
import {
  summaryModeOptions,
  customPromptModePrefix,
} from "../constants/summaryModeOptions";
import { useTheme } from "@mui/material/styles";
import {
  jstNow,
  formatDateTimeYYYYMMDD_HHmmss,
} from "../helper/dateTimeHelper";

import { getResultFromUploadedFile } from "../graphql/queries";

import AnalyticsWithTenant from "../helper/AnalyticsWithTenant";
import { CreateSummarizedTextCount } from "../CreateSummarizedTextCount";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { API } from "@aws-amplify/api";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/auth";
import LanguageModeSelect from "./LanguageModeSelect";
import {
  languageModeOptions,
  downloadAndSharingLabels,
} from "../constants/languageModeOptions";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { TenantOptionsContext } from "./TenantOptionsProvider";

// TODO: 初回リリースのダウンタイムを防ぐために課金体系の処理閉鎖
// import MonthlyTenantProcessedTextCountRepository from "../repositories/MonthlyTenantProcessedTextCountRepository";
// import { CreateVoiceInputTextCount } from "../CreateVoiceInputTextCount";
import { getTenantUserGroup } from "../helper/userInfoHelper";
// import useUsageLimitExceeded from "../helper/useUsageLimitExceeded";

const RECORD_END_KEYWORD_LIST = [
  "入力終わり",
  "入力終わり、",
  "入力終わり。",
  "入力おわり",
  "入力おわり、",
  "入力おわり。",
];

const primaryButtonSize = {
  width: "10rem",
};

// TODO: 初回リリースのダウンタイムを防ぐために課金体系の処理閉鎖
// const monthlyTenantProcessedTextCountRepository =
//   new MonthlyTenantProcessedTextCountRepository();

const RecordButton = ({
  sx,
  isRecording,
  messageInput,
  isStartingRecord,
  startOrStopRecording,
}) => {
  const _sx = sx;

  let variant = "contained";
  if (isRecording || (!isRecording && messageInput)) {
    variant = "outlined";
  }

  if (isStartingRecord) {
    return (
      <Button
        variant="contained"
        color="primary"
        disabled
        startIcon={
          <CircularProgress
            size={15}
            color="inherit"
            sx={{
              mr: "-0.2rem",
            }}
          />
        }
        sx={{ ml: "0.4rem", ...primaryButtonSize, ..._sx }}
      >
        音声入力起動中
      </Button>
    );
  }

  RecordButton.propTypes = {
    sx: PropTypes.object,
    isRecording: PropTypes.any,
    messageInput: PropTypes.any,
    isStartingRecord: PropTypes.any,
    startOrStopRecording: PropTypes.any,
  };

  return (
    <Button
      id="record-Button"
      variant={variant}
      color={isRecording ? "monoqlo" : "primary"}
      onClick={startOrStopRecording}
      startIcon={
        isRecording ? (
          <CloseIcon sx={{ width: "15", margin: "-0.2rem" }} />
        ) : (
          <MicIcon sx={{ width: "15", margin: "-0.2rem" }} />
        )
      }
      sx={{ ml: "0.4rem", ...primaryButtonSize, ..._sx }}
    >
      {isRecording ? "入力を終了" : "音声を入力"}
    </Button>
  );
};

function InputChat() {
  const [confirmationDialogOpen, setClearConfirmationDialogOpen] =
    useState(false);
  const [messageInput, setMessageInput] = useState("");
  const [messageOutput, setmessageOutput] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [summaryMode, setSummaryMode] = useState(summaryModeOptions.normal);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { user } = useAuthenticator((context) => [context.user]);
  // TODO: 初回リリースのダウンタイムを防ぐために課金体系の処理閉鎖
  // const [currentVoiceInputTextCount, setCurrentVoiceInputTextCount] =
  //   useState(0);
  // const [isRunning, setIsRunning] = useState(false);
  // const [intervalId, setIntervalId] = useState(null);
  // const currentVoiceInputTextCountRef = useRef(currentVoiceInputTextCount);
  // const [totalVoiceInputTextCount, setTotalVoiceInputTextCount] = useState(0);
  // const [voiceInputStartTime, setVoiceInputStartTime] = useState("");
  const userInfo = user.getSignInUserSession().getAccessToken().payload;
  const tenantId = getTenantUserGroup(userInfo);
  // const { currentIsUsageLimitExceeded, checkUsageLimitExceeded } =
  //   useUsageLimitExceeded();
  const [latestSpeakerLabel, setLatestSpeakerLabel] = useState("");
  const [isModeSelectDisable, setModeSelectDisable] = useState(false);
  const { tenantOptions } = useContext(TenantOptionsContext);

  const [inputLanguageMode, setInputLanguageMode] = useState(
    languageModeOptions.japanese,
  );
  const [outputLanguageMode, setOutputLanguageMode] = useState(
    languageModeOptions.japanese,
  );
  const [isLanguageModeSelectDisable, setLanguageModeSelectDisable] =
    useState(true);
  const detailsRef = useRef(null);

  const {
    recognitionResultInProcess,
    recognitionFinalizedResult,
    isRecording,
    isStartingRecord,
    startRecording,
    stopRecording,
    // TODO: 初回リリースのダウンタイムを防ぐために課金体系の処理閉鎖
    // voiceInputUsageTime,
    // setVoiceInputUsageTime,
  } = useVoiceRecognition();

  const ref = useRef(null);
  const inputRef = useRef(null);

  const returnTop = () => {
    scrollMUIMainComponent(ref.current, 0, -66);
  };

  const validateSessionId = (sessionId) => {
    const regex =
      /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/;
    return regex.test(sessionId);
  };

  useEffect(() => {
    let message = "文字起こし結果の取得に失敗しました。";
    try {
      const sessionId = sessionStorage.getItem("sessionId");
      const fetchVoiceRecognitionData = async () => {
        return await API.graphql({
          query: getResultFromUploadedFile,
          variables: { sessionId: sessionId },
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        });
      };

      if (sessionId) {
        if (!validateSessionId(sessionId)) {
          message = "指定されたセッションIDが不正です。URLを確認してください。";
          throw new Error("Invalid session ID.");
        }
        fetchVoiceRecognitionData()
          .catch((error) => {
            console.error(error);
            switch (error.errors[0].message) {
              case "Not Authorized": // 権限がない場合は「見つからなかった」ことにする
              case "sessionId Not Found":
                message +=
                  "指定されたセッションIDが見つかりませんでした。URLを確認してください。";
                break;
              case "Response Error: NOT FOUND":
                message =
                  "文字起こし結果の有効期限が切れています。もう一度ファイルをアップロードしてください。";
                break;
              case "Response Error: UNAUTHORIZED":
                message =
                  "文字起こし結果取得中にエラーが発生しました。もう一度リンクをクリックしてください。";
                break;
              default:
            }
            enqueueSnackbar(message, { variant: "error" });
          })
          .then((response) => {
            if (response?.data) {
              const resultText = response.data.getResultFromUploadedFile;
              setMessageInput(resultText);
              message = "文字起こし結果を入力しました。";
              enqueueSnackbar(message, { variant: "success" });
              AnalyticsWithTenant.record({
                name: "fileUploadResultViewed",
              });
            }
          });
      }

      // 言語選択が無効の場合は、デフォルトの日本語に設定する
      if (!tenantOptions?.multi_language_enabled) {
        setInputLanguageMode(languageModeOptions.japanese);
        setOutputLanguageMode(languageModeOptions.japanese);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(message, { variant: "error" });
    } finally {
      sessionStorage.removeItem("sessionId");
    }
  }, []);

  useEffect(() => {
    // Scroll to the caret position when the voice input result is updated.
    const element = inputRef.current;
    scrollTextareaToCaret(element);
  }, [recognitionResultInProcess]);

  useEffect(() => {
    let resultText = "";

    if (!recognitionFinalizedResult) {
      return;
    }

    if (
      summaryMode.value === summaryModeOptions.meetingSpeakerRecognition.value
    ) {
      recognitionFinalizedResult.forEach(({ text, speakerLabel }) => {
        if (speakerLabel !== latestSpeakerLabel) {
          // 現在の話者と前回の話者が異なる場合
          if (latestSpeakerLabel !== "") {
            // 話者切り替わりのタイミングで改行する
            resultText += "\n";
          }
          // ラベルをテキストに付与する
          resultText += `<${speakerLabel}>\n${text}`;
          setLatestSpeakerLabel(speakerLabel);
        } else {
          // 前回の話者と同じ場合
          if (inputLanguageMode === languageModeOptions.english) {
            // 入力言語が英語の場合で、前回と同じ話者の場合はスペースを入れる
            resultText += " ";
          }
          resultText += text;
        }
      });
    } else {
      resultText = recognitionFinalizedResult.text;
    }

    // End voice input when the recognized string ends with the voice input end keyword.
    let endKeyword = "";
    if (
      RECORD_END_KEYWORD_LIST.some((keyword) => {
        endKeyword = keyword;
        return resultText.endsWith(keyword);
      })
    ) {
      // Add the text other than the voice input end keyword to the message.
      setMessageInput((prev) => prev + resultText.replace(endKeyword, ""));
      startOrStopRecording();
      return;
    }
    // TODO: 初回リリースのダウンタイムを防ぐために課金体系の処理閉鎖
    // setCurrentVoiceInputTextCount(
    //   currentVoiceInputTextCount + recognitionFinalizedResult.length,
    // );
    // setTotalVoiceInputTextCount(
    //   totalVoiceInputTextCount + recognitionFinalizedResult.length,
    // );
    setMessageInput((prev) => prev + resultText);
    // TODO: Sxroll to the bottom of the text field.
    // When the number of lines in the text field of messageInput exceeds the number of lines, the exceeded part is not displayed.
    // Unlike manual input, the string being entered in the text field is not scrolled to be visible.
  }, [recognitionFinalizedResult]);

  // Send message when recording is completed.
  useEffect(() => {
    if (isRecording) {
      return;
    }
    const send = async () => {
      await sendMessage();
    };
    send();
  }, [isRecording]);

  const startOrStopRecording = async () => {
    try {
      setModeSelectDisable(!isRecording);
      if (isRecording) {
        stopRecording();
        // TODO: 初回リリースのダウンタイムを防ぐために課金体系の処理閉鎖
        // stopIntervalVoiceCountRequest();
        return;
      } else {
        // TODO: 初回リリースのダウンタイムを防ぐために課金体系の処理閉鎖
        // startIntervalVoiceCountRequest();
        // setVoiceInputStartTime(new Date().toISOString());
        // switch depends on summary mode
        loadLanguageMode(summaryMode.value);
        let options = {};
        switch (summaryMode.value) {
          case summaryModeOptions.normal.value:
            options = {
              useDiarizer: false,
              language: languageModeOptions.japanese,
            };
            break;
          case summaryModeOptions.meeting.value:
            options = { useDiarizer: false, language: inputLanguageMode };
            break;
          case summaryModeOptions.meetingSpeakerRecognition.value:
            options = { useDiarizer: true, language: inputLanguageMode };
            break;
          default:
            if (summaryMode.value.includes(customPromptModePrefix)) {
              options = {
                useDiarizer: false,
                language: languageModeOptions.japanese,
              };
              break;
            }
            throw Error("Summary mode is invalid.", {
              cause: { code: "invalidSummaryMode" },
            });
        }
        await startRecording(options);
      }
    } catch (e) {
      console.error(e);
      let message = "エラーが発生しました。";
      if (e.cause && e.cause.code === "invalidSummaryMode") {
        message = "まとめモードが不正です。";
      }
      enqueueSnackbar(message, { variant: "error" });
    }
  };

  const sendMessage = async () => {
    setmessageOutput("");
    setLoading(true);
    AnalyticsWithTenant.record({
      name: "sendMessage",
      attributes: { mode: summaryMode.value },
    });
    scrollMUIMainComponent(ref.current, 0, -66);
    if (messageInput.trim() === "") {
      setLoading(false);
      return;
    }
    // TODO: 初回リリースのダウンタイムを防ぐために課金体系の処理閉鎖
    // await checkUsageLimitExceeded();
    // if (currentIsUsageLimitExceeded.current) {
    //   setLoading(false);
    //   return;
    // }
    try {
      // switch depends on summary mode
      let output = "";
      const summaryStartTime = new Date().toISOString();
      // TODO: 初回リリースのダウンタイムを防ぐために課金体系の処理閉鎖
      // let summarizedTextCount = messageInput.length;
      switch (summaryMode.value) {
        case summaryModeOptions.normal.value:
          output = await sendMessageNormal();
          break;
        case summaryModeOptions.meeting.value:
        case summaryModeOptions.meetingSpeakerRecognition.value:
          output = await sendMessageMeeting();
          break;
        default:
          if (summaryMode.value.includes(customPromptModePrefix)) {
            output = await sendMessageCustomPrompt();
            break;
          }
          output = await sendMessageNormal();
          break;
      }
      setmessageOutput(output);
      if (!isIphone()) {
        copyOutput(output);
      }

      await saveSummarizedTextCount(summaryStartTime);
      // TODO: 初回リリースのダウンタイムを防ぐために課金体系の処理閉鎖
      // await saveVoiceInputTextCount(voiceInputStartTime);

      // await updateMonthlyProcessedTextCount(
      //   summarizedTextCount -
      //     totalVoiceInputTextCount +
      //     currentVoiceInputTextCountRef.current,
      // );
      // Reset the voice input text count.
      // TODO: 初回リリースのダウンタイムを防ぐために課金体系の処理閉鎖
      // setCurrentVoiceInputTextCount(0);
      // setTotalVoiceInputTextCount(0);
    } catch (e) {
      console.error(e);
      let snackbarMessage = "";
      switch (e?.cause?.code) {
        case "Token refresh error":
          snackbarMessage =
            "エラーが発生しました。もう一度お試しいただくか、ログインし直してから再度実行してください。";
          break;
        case "exceeding_number_of_characters":
        case "context_length_exceeded":
        case "input_message_exceed_max_length":
          snackbarMessage =
            "一度に要約できる文字数を超過しました。入力内容を減らして、もう一度お試しください。";
          break;
        case "invalid_parameter_value":
          snackbarMessage =
            "入力内容が不正です。ログインし直してから再度実行してください。";
          break;
        case "content_filter":
          snackbarMessage =
            "生成した文章に不適切な内容が含まれていました。入力内容をご確認の上、もう一度お試しください。";
          break;
        case "exceeded_available_rate":
          snackbarMessage =
            "アクセスが集中しています。時間をあけて、もう一度お試しください。";
          break;
        case "no_tenant_exists":
          snackbarMessage = "エラーが発生しました。テナントが見つかりません。";
          break;
        case "no_resource_exists":
          snackbarMessage =
            "エラーが発生しました。有効なリソースが見つかりません。";
          break;
        default:
          snackbarMessage = e.message;
      }

      enqueueSnackbar(snackbarMessage, { variant: "error" });
    } finally {
      setLoading(false);
      // TODO: 初回リリースのダウンタイムを防ぐために課金体系の処理閉鎖
      // setVoiceInputUsageTime(0);
      // 2回目以降の音声ラベルと同様の動作をさせるため、ここではダミーの値をセットする
      setLatestSpeakerLabel("dummy");
    }
  };

  const sendMessageNormal = async () => {
    let { summarizedText, revisedText } = await chat(
      messageInput,
      summaryMode.value,
      languageModeOptions.japanese.value,
      languageModeOptions.japanese.value,
    );

    summarizedText = convertLineFeedCode(summarizedText);
    revisedText = convertLineFeedCode(revisedText);

    return `ポイント\n${summarizedText}\n\n校正文\n${revisedText}`;
  };

  const sendMessageMeeting = async () => {
    const { summarizedText } = await chat(
      messageInput,
      summaryMode.value,
      inputLanguageMode.value,
      outputLanguageMode.value,
    );
    return summarizedText;
  };

  const sendMessageCustomPrompt = async () => {
    const { summarizedText } = await chat(
      messageInput,
      summaryMode.promptId,
      languageModeOptions.japanese.value,
      languageModeOptions.japanese.value,
    );
    return summarizedText;
  };

  const saveSummarizedTextCount = async (startTime) => {
    await CreateSummarizedTextCount(
      startTime,
      messageInput.length,
      summaryMode.value,
      user.username,
      inputLanguageMode.value,
      outputLanguageMode.value,
      tenantId,
    );
  };

  // TODO: 初回リリースのダウンタイムを防ぐために課金体系の処理閉鎖
  // const saveVoiceInputTextCount = async (startTime) => {
  //   if (startTime) {
  //     await CreateVoiceInputTextCount(
  //       startTime,
  //       totalVoiceInputTextCount,
  //       Math.floor(voiceInputUsageTime / 1000) % 60,
  //       user.username,
  //       summaryMode.value,
  //       tenantId,
  //     );
  //   }
  // };

  // TODO: 初回リリースのダウンタイムを防ぐために課金体系の処理閉鎖
  // const updateMonthlyProcessedTextCount = async (textCount) => {
  //   try {
  //     await monthlyTenantProcessedTextCountRepository.incrementMonthlyTenantProcessedTextCount(
  //       textCount,
  //       tenantId,
  //     );
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  // TODO: 初回リリースのダウンタイムを防ぐために課金体系の処理閉鎖
  // const startIntervalVoiceCountRequest = () => {
  //   if (!isRunning) {
  //     const id = setInterval(() => {
  //       updateMonthlyProcessedTextCount(currentVoiceInputTextCountRef.current);
  //       checkUsageLimitExceeded();
  //       // Reset the voice input text count.
  //       setCurrentVoiceInputTextCount(0);
  //     }, 60000);

  // TODO: 初回リリースのダウンタイムを防ぐために課金体系の処理閉鎖
  //     setIntervalId(id);
  //     setIsRunning(true);
  //   }
  // };

  // TODO: 初回リリースのダウンタイムを防ぐために課金体系の処理閉鎖
  // const stopIntervalVoiceCountRequest = () => {
  //   if (isRunning && intervalId) {
  //     clearInterval(intervalId);
  //     setIsRunning(false);
  //   }
  // };

  /**
   * Convert line feed code \\n to \n.
   * @param {string} text
   * @returns {string}
   */
  const convertLineFeedCode = (text) => {
    return text.replaceAll("\\n", "\n");
  };

  /**
   * Copy output message to clipboad.
   * Pass the text as an argument when messageOutput is not set immediately after setMessageOutput.
   */
  const copyOutput = async (text) => {
    await copyTextToClipboard(text ?? messageOutput);
    AnalyticsWithTenant.record({
      name: "copyOutput",
    });
    enqueueSnackbar("出力内容をコピーしました。", { variant: "success" });
  };

  /**
   * Copy output message to clipboad.
   */
  const copyInput = async () => {
    await copyTextToClipboard(messageInput);
    AnalyticsWithTenant.record({
      name: "copyInput",
    });
    enqueueSnackbar("入力内容をコピーしました。", { variant: "success" });
  };

  const getContentBySummaryMode = () => {
    let content = {};
    let inputLabel = "";
    let outputLabel = "";
    const outputLanguage =
      outputLanguageMode.value || languageModeOptions.Japanese;

    switch (summaryMode.value) {
      case summaryModeOptions.normal.value:
        inputLabel =
          downloadAndSharingLabels[languageModeOptions.japanese.value].normal
            .input;
        outputLabel =
          downloadAndSharingLabels[languageModeOptions.japanese.value].normal
            .output;
        break;
      case summaryModeOptions.meeting.value:
      case summaryModeOptions.meetingSpeakerRecognition.value:
        // 入力言語のラベルは出力言語のラベルに合わせる
        inputLabel = downloadAndSharingLabels[outputLanguage].meeting.input;
        outputLabel = downloadAndSharingLabels[outputLanguage].meeting.output;
        break;
      default:
        if (summaryMode.value.includes(customPromptModePrefix)) {
          inputLabel =
            downloadAndSharingLabels[languageModeOptions.japanese.value]
              .customPrompt.input;
          outputLabel =
            downloadAndSharingLabels[languageModeOptions.japanese.value]
              .customPrompt.output;
          break;
        }
        throw Error("Summary mode is invalid.", {
          cause: { code: "invalidSummaryMode" },
        });
    }

    content = {
      text: `[${outputLabel}]\n${messageOutput}\n\n[${inputLabel}]\n${messageInput}`,
    };
    return content;
  };

  const downloadContent = async (filename, content) => {
    try {
      await downloadText(filename, content);
      AnalyticsWithTenant.record({
        name: "startDownloadContent",
      });
      enqueueSnackbar("入出力内容のダウンロードを開始しました。", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(
        `入出力内容のダウンロード開始に失敗しました。: ${error.message}`,
        {
          variant: "error",
        },
      );
    }
  };

  const shareContent = async () => {
    try {
      await navigator.share(getContentBySummaryMode());
      AnalyticsWithTenant.record({
        name: "shareContent",
      });
    } catch (e) {
      console.error(e);
      let message = "エラーが発生しました。";
      if (e.name == "AbortError") {
        message = "共有がキャンセルされました。";
        enqueueSnackbar(message);
      } else if (e.cause && e.cause.code === "invalidSummaryMode") {
        message = "まとめモードが不正です。";
        enqueueSnackbar(message, { variant: "error" });
      } else {
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  };

  const handleClickCopyOutput = async () => {
    await copyOutput();
  };

  const handleClickDownload = () => {
    let content = getContentBySummaryMode().text;
    downloadContent(
      `o-ninja_${formatDateTimeYYYYMMDD_HHmmss(jstNow())}.txt`,
      content,
    );
  };

  const handleShareContent = async () => {
    await shareContent();
  };

  const handleClickClear = () => {
    setClearConfirmationDialogOpen(true);
  };

  const closeClearConfirmed = () => {
    setMessageInput("");
    enqueueSnackbar("クリアしました。");
    // 初回と同様の音声ラベル状態にするため、ここでは空文字をセットする
    setLatestSpeakerLabel("");
    setClearConfirmationDialogOpen(false);
  };

  const closeClearNotConfirmed = () => {
    setClearConfirmationDialogOpen(false);
  };

  const ClearConfirmationDialog = () => {
    return (
      <Dialog
        open={confirmationDialogOpen}
        onClose={closeClearNotConfirmed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"クリアしてよろしいですか？"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            テキストエリアに入力されている文字をクリアします。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeClearNotConfirmed}>クリアしない</Button>
          <Button onClick={closeClearConfirmed} autoFocus>
            クリアする
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleSummaryModeChange = (event) => {
    setSummaryMode(event);

    const isMeetingMode = [
      summaryModeOptions.meetingSpeakerRecognition.value,
      summaryModeOptions.meeting.value,
    ].includes(event.value);
    if (!isMeetingMode) {
      setLanguageModeSelectDisable(true);
    } else {
      loadLanguageMode(event.value);
      setLanguageModeSelectDisable(false);
    }
  };

  const loadLanguageMode = (summaryMode) => {
    if (!tenantOptions?.multi_language_enabled) {
      return;
    }
    const inputKey = `inputLanguageMode-${summaryMode}`;
    const inputLang = localStorage.getItem(inputKey);
    const matchedInputOption = Object.values(languageModeOptions).find(
      (option) => option.value === inputLang,
    );
    setInputLanguageMode(matchedInputOption || languageModeOptions.japanese);

    const outputKey = `outputLanguageMode-${summaryMode}`;
    const outputLang = localStorage.getItem(outputKey);
    const matchedOutputOption = Object.values(languageModeOptions).find(
      (option) => option.value === outputLang,
    );
    setOutputLanguageMode(matchedOutputOption || languageModeOptions.japanese);
  };

  const handleInputLanguageModeChange = (event) => {
    setInputLanguageMode(event);
    const key = `inputLanguageMode-${summaryMode.value}`;
    localStorage.setItem(key, event.value);
    AnalyticsWithTenant.record({
      name: "changeInputLanguageMode",
    });
  };

  const handleOutputLangaueModeChange = (event) => {
    setOutputLanguageMode(event);
    const key = `outputLanguageMode-${summaryMode.value}`;
    localStorage.setItem(key, event.value);
    AnalyticsWithTenant.record({
      name: "changeOutputLanguageMode",
    });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item>
          <TenantCustomMessage id="custom_message" />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "column",
            justifyContent: "start",
            textAlign: "start",
            pb: theme.spacing(3),
          }}
        >
          <Box sx={{ pb: theme.spacing(3) }}>
            <SummaryModeSelect
              option={summaryMode}
              onChange={(event) => {
                handleSummaryModeChange(event);
                if (detailsRef.current) {
                  detailsRef.current.open = false;
                }
              }}
              disabled={isModeSelectDisable}
            />
          </Box>
          {tenantOptions?.multi_language_enabled && (
            <details
              id="inputOutputLangModeSelect"
              ref={detailsRef}
              onClick={(e) => {
                if (!isModeSelectDisable && !isLanguageModeSelectDisable) {
                  e.preventDefault();
                }
              }}
              style={{
                color:
                  isModeSelectDisable || isLanguageModeSelectDisable
                    ? "#BFBFBF"
                    : "black",
              }}
            >
              <summary
                id="languageModeSelect"
                onClick={() => {
                  if (detailsRef.current) {
                    detailsRef.current.open = !detailsRef.current.open;
                  }
                }}
              >
                <Typography variant="subtitle1" component="span">
                  入出力言語設定
                </Typography>
              </summary>
              <Box sx={{ mt: theme.spacing(3) }} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                <LanguageModeSelect
                  option={inputLanguageMode}
                  onChange={handleInputLanguageModeChange}
                  disabled={isModeSelectDisable}
                  flowType={"Input"}
                />
                <ArrowForwardIcon />
                <LanguageModeSelect
                  option={outputLanguageMode}
                  onChange={handleOutputLangaueModeChange}
                  disabled={isModeSelectDisable}
                  flowType={"Output"}
                />
              </Box>
            </details>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            pb: theme.spacing(5),
          }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: "0.1rem",
            }}
          >
            <Box sx={{ display: "flex", flexFlow: "column" }}>
              <Typography
                variant="subtitle1"
                sx={{
                  margin: "auto 0",
                  mr: "1rem",
                  mb: theme.spacing(1),
                  textAlign: "left",
                }}
              >
                報告内容の入力
              </Typography>

              {isRecording && (
                <Typography
                  variant="subtitle2"
                  sx={{ margin: "auto 0", textAlign: "left" }}
                >
                  {`音声入力中..."${RECORD_END_KEYWORD_LIST[0]}
                  "と言うと終了します。`}
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item>
            <TextField
              id="input"
              inputRef={inputRef}
              multiline
              fullWidth
              rows={isRecording ? 13 : 12}
              value={messageInput + recognitionResultInProcess}
              onChange={(event) => {
                // Disable editing the text area while voice input is in progress.
                if (recognitionResultInProcess !== "") {
                  return;
                }
                setMessageInput(event.target.value);
              }}
              sx={isRecording ? { mb: "0.5rem" } : { mb: "0" }}
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            {!isRecording && (
              <Box sx={{ display: "flex", gap: "0.4rem" }}>
                <Button
                  id="inputCopy"
                  variant="text"
                  disabled={!messageInput || loading}
                  onClick={copyInput}
                  startIcon={
                    <ContentCopyIcon
                      sx={{ width: "14px", margin: "-0.2rem" }}
                    />
                  }
                  size="small"
                >
                  コピー
                </Button>
                <Button
                  id="clearInputText"
                  variant="text"
                  disabled={!messageInput || loading}
                  onClick={handleClickClear}
                  startIcon={
                    <ClearIcon sx={{ width: "14px", margin: "-0.2rem" }} />
                  }
                  size="small"
                >
                  クリア
                </Button>
              </Box>
            )}
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: "0.2rem",
              gap: { xs: "12px", md: "20px" },
            }}
          >
            <Button
              id="summary-button"
              variant="contained"
              color="secondary"
              disabled={!messageInput || loading}
              onClick={sendMessage}
              sx={{
                margin: "0.4rem 0",
                ...primaryButtonSize,
              }}
              startIcon={
                <AutoAwesomeIcon sx={{ width: "15px", margin: "-0.2rem" }} />
              }
            >
              文章をきれいに
            </Button>
            <RecordButton
              sx={{ margin: "0.4rem 0" }}
              // TODO: 初回リリースのダウンタイムを防ぐために課金体系の処理閉鎖
              isRecording={isRecording}
              // isRecording={isRecording && !currentIsUsageLimitExceeded.current}
              messageInput={messageInput}
              isStartingRecord={
                // TODO: 初回リリースのダウンタイムを防ぐために課金体系の処理閉鎖
                // isStartingRecord && !currentIsUsageLimitExceeded.current
                isStartingRecord
              }
              startOrStopRecording={startOrStopRecording}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} ref={ref}>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "1.5rem",
            }}
          >
            <Grid sx={{ margin: "auto 0" }}>
              <Typography
                variant="subtitle1"
                sx={{ textAlign: "left", mb: theme.spacing(1) }}
              >
                報告内容
              </Typography>
            </Grid>
          </Grid>
          <Grid item sx={{ position: "relative" }}>
            <TextField
              id="outlined-multiline-static"
              multiline
              fullWidth
              rows={15}
              onChange={(event) => {
                setmessageOutput(event.target.value);
              }}
              value={messageOutput}
            />
            <Box
              sx={{
                position: "absolute",
                top: "35%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {loading && (
                <>
                  <ProcessingProgress />
                  <img
                    src={`${process.env.PUBLIC_URL}/ninja_face_animation.gif`}
                    style={{ width: "7.5rem", paddingTop: theme.spacing(3) }}
                    draggable="false"
                  />
                </>
              )}
            </Box>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                id="outputCopy"
                variant="text"
                disabled={!messageOutput || loading}
                onClick={handleClickCopyOutput}
                startIcon={
                  <ContentCopyIcon sx={{ width: "14px", margin: "-0.2rem" }} />
                }
                size="small"
              >
                コピー
              </Button>
            </Grid>
            <Grid>
              <Button
                id="saveContent"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!messageOutput || loading}
                onClick={isIphone() ? handleShareContent : handleClickDownload}
                sx={{
                  margin: "0.4rem 0",
                  width: { xs: "100%", md: "160px" },
                }}
                startIcon={
                  isIphone() ? (
                    <IosShareIcon sx={{ width: "15px", margin: "-0.2rem" }} />
                  ) : (
                    <DownloadIcon sx={{ width: "15px", margin: "-0.2rem" }} />
                  )
                }
              >
                {isIphone() ? "共有" : "ダウンロード"}
              </Button>
            </Grid>
            <Grid>
              <Button
                variant="text"
                onClick={returnTop}
                size="small"
                sx={{ mt: "0" }}
              >
                入力に戻る
              </Button>
            </Grid>
            <Typography variant="subtitle2" align="left">
              AIによる文章生成のため不適切な表現をする場合や、正しい情報が表示されない場合があります。
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <ClearConfirmationDialog />
    </Box>
  );
}

export default InputChat;
