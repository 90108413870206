import { Box, Button, TextField, Typography } from "@mui/material";
import useWordRegister from "../../hooks/useWordRegister";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { useTheme } from "@mui/material/styles";
import { isIphone } from "../../helper/deviceHelper";

const primaryButtonSize = {
  width: "10rem",
};

export default function RegisterNewWord() {
  const successSaveProfiledWordsMessage = "固有名詞を保存しました。";

  const theme = useTheme();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    profiledWordsFileds,
    registerProfiledWords,
    registerProfiledWordsErrors,
    saveProfiledWords,
    submitProfiledWords,
  } = useWordRegister();

  const handleSubmitProfiledWords = submitProfiledWords(async (data) => {
    await saveProfiledWords(data.profiledWords);
    enqueueSnackbar(successSaveProfiledWordsMessage, { variant: "success" });
    navigate("/word-registration");
  });

  return (
    <Box
      sx={{
        paddingX: {
          xs: theme.spacing(3),
        },
        width: { md: "652px" },
        margin: "auto",
      }}
    >
      <form onSubmit={handleSubmitProfiledWords}>
        {profiledWordsFileds.map((item, index) => {
          const errorMessage =
            registerProfiledWordsErrors.profiledWords &&
            registerProfiledWordsErrors.profiledWords[index];
          return (
            <div key={item.id}>
              <Box sx={{ display: { xs: "block", md: "none" } }}>
                <Button
                  id="saveWordSp"
                  type="submit"
                  variant="text"
                  color="primary"
                  size="small"
                  sx={{
                    position: "fixed",
                    zIndex: "10000",
                    top: "0.4rem",
                    right: "0",
                  }}
                >
                  保存
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "auto",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "0.25rem",
                  mt: { xs: theme.spacing(5), md: theme.spacing(10) },
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ margin: "0", textAlign: "left" }}
                >
                  表記
                </Typography>
                <TextField
                  id="personalPropernounWordTextfeild"
                  fullWidth
                  variant="outlined"
                  sx={{ mt: "0", mb: "0.4rem" }}
                  {...registerProfiledWords(`profiledWords.${index}.word`)}
                  error={!!errorMessage?.word?.message}
                  helperText={errorMessage?.word?.message}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "auto",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "0.25rem",
                  mt: { xs: theme.spacing(5) },
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ margin: "0", textAlign: "left" }}
                >
                  読み
                </Typography>
                <TextField
                  id="personalPropernounReadingTextfeild"
                  fullWidth
                  variant="outlined"
                  sx={{ mt: "0", mb: "1.25rem" }}
                  {...registerProfiledWords(`profiledWords.${index}.reading`)}
                  error={!!errorMessage?.reading?.message}
                  helperText={errorMessage?.reading?.message}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  maxWidth: "auto",
                  justifyContent: "center",
                  gap: "0.75rem",
                }}
              >
                <Button
                  type="submit"
                  variant={isIphone() ? "text" : "outlined"}
                  color="monoqlo"
                  onClick={() => navigate("/word-registration")}
                  sx={{ ...primaryButtonSize }}
                >
                  キャンセル
                </Button>
                <Button
                  id="saveWordPc"
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    ...primaryButtonSize,
                    display: { xs: "none", md: "block" },
                  }}
                >
                  保存
                </Button>
              </Box>
            </div>
          );
        })}
      </form>
    </Box>
  );
}
