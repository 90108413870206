/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRequest = /* GraphQL */ `
  query GetRequest($id: ID!) {
    getRequest(id: $id) {
      id
      message
      response
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRequests = /* GraphQL */ `
  query ListRequests(
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        response
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPlanMaster = /* GraphQL */ `
  query GetPlanMaster($planId: ID!) {
    getPlanMaster(planId: $planId) {
      planId
      planName
      maxSummarizeTextCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPlanMasters = /* GraphQL */ `
  query ListPlanMasters(
    $planId: ID
    $filter: ModelPlanMasterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPlanMasters(
      planId: $planId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        planId
        planName
        maxSummarizeTextCount
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTenantUnderContractPlan = /* GraphQL */ `
  query GetTenantUnderContractPlan($id: ID!) {
    getTenantUnderContractPlan(id: $id) {
      id
      planId {
        planId
        planName
        maxSummarizeTextCount
        createdAt
        updatedAt
        __typename
      }
      appliedStatus
      contractStartDate
      contractEndDate
      tenantId
      monthlyTextCountResetDate
      createdAt
      updatedAt
      tenantUnderContractPlanPlanIdPlanId
      __typename
    }
  }
`;
export const listTenantUnderContractPlans = /* GraphQL */ `
  query ListTenantUnderContractPlans(
    $filter: ModelTenantUnderContractPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenantUnderContractPlans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        appliedStatus
        contractStartDate
        contractEndDate
        tenantId
        monthlyTextCountResetDate
        createdAt
        updatedAt
        tenantUnderContractPlanPlanIdPlanId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOptionSettings = /* GraphQL */ `
  query GetOptionSettings($id: ID!) {
    getOptionSettings(id: $id) {
      id
      custome_message_enabled
      custome_message_contents
      file_upload_enabled
      multi_language_enabled
      tenant_id
      __typename
    }
  }
`;
export const listOptionSettings = /* GraphQL */ `
  query ListOptionSettings(
    $filter: ModelOptionSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOptionSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        custome_message_enabled
        custome_message_contents
        file_upload_enabled
        multi_language_enabled
        tenant_id
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAzureOAIEndpointPaths = /* GraphQL */ `
  query GetAzureOAIEndpointPaths($id: ID!) {
    getAzureOAIEndpointPaths(id: $id) {
      id
      azureOpenAIEndpoint
      relayRequestAzureApiUrl
      relayRequestAzureApikey
      __typename
    }
  }
`;
export const listAzureOAIEndpointPaths = /* GraphQL */ `
  query ListAzureOAIEndpointPaths(
    $filter: ModelAzureOAIEndpointPathsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAzureOAIEndpointPaths(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        azureOpenAIEndpoint
        relayRequestAzureApiUrl
        relayRequestAzureApikey
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const azureOAIEndpointPathsByAzureOpenAIEndpoint = /* GraphQL */ `
  query AzureOAIEndpointPathsByAzureOpenAIEndpoint(
    $azureOpenAIEndpoint: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAzureOAIEndpointPathsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    azureOAIEndpointPathsByAzureOpenAIEndpoint(
      azureOpenAIEndpoint: $azureOpenAIEndpoint
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        azureOpenAIEndpoint
        relayRequestAzureApiUrl
        relayRequestAzureApikey
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCorporateProperNoun = /* GraphQL */ `
  query GetCorporateProperNoun($id: ID!) {
    getCorporateProperNoun(id: $id) {
      id
      writing
      reading
      categoryId
      tenant_id
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCorporateProperNouns = /* GraphQL */ `
  query ListCorporateProperNouns(
    $filter: ModelCorporateProperNounFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCorporateProperNouns(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        writing
        reading
        categoryId
        tenant_id
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCorporateProperNounCategory = /* GraphQL */ `
  query GetCorporateProperNounCategory($id: ID!) {
    getCorporateProperNounCategory(id: $id) {
      id
      sortOrder
      name
      tenant_id
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCorporateProperNounCategories = /* GraphQL */ `
  query ListCorporateProperNounCategories(
    $filter: ModelCorporateProperNounCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCorporateProperNounCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sortOrder
        name
        tenant_id
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTenantSummarizedTextCount = /* GraphQL */ `
  query GetTenantSummarizedTextCount($id: ID!) {
    getTenantSummarizedTextCount(id: $id) {
      summaryStartDateTime
      textCount
      userId
      summaryMode
      inputLang
      outputLang
      tenantId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTenantSummarizedTextCounts = /* GraphQL */ `
  query ListTenantSummarizedTextCounts(
    $filter: ModelTenantSummarizedTextCountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenantSummarizedTextCounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        summaryStartDateTime
        textCount
        userId
        summaryMode
        inputLang
        outputLang
        tenantId
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTenantVoiceInputTextCount = /* GraphQL */ `
  query GetTenantVoiceInputTextCount($id: ID!) {
    getTenantVoiceInputTextCount(id: $id) {
      voiceInputStartDateTime
      textCount
      inputDuration
      userId
      summaryMode
      tenantId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTenantVoiceInputTextCounts = /* GraphQL */ `
  query ListTenantVoiceInputTextCounts(
    $filter: ModelTenantVoiceInputTextCountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenantVoiceInputTextCounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        voiceInputStartDateTime
        textCount
        inputDuration
        userId
        summaryMode
        tenantId
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMonthlyTenantProcessedTextCount = /* GraphQL */ `
  query GetMonthlyTenantProcessedTextCount($id: ID!) {
    getMonthlyTenantProcessedTextCount(id: $id) {
      id
      planId {
        planId
        planName
        maxSummarizeTextCount
        createdAt
        updatedAt
        __typename
      }
      activatedStatus
      monthlyTextCount
      monthlyTextCountStartDate
      tenantId
      createdAt
      updatedAt
      monthlyTenantProcessedTextCountPlanIdPlanId
      __typename
    }
  }
`;
export const listMonthlyTenantProcessedTextCounts = /* GraphQL */ `
  query ListMonthlyTenantProcessedTextCounts(
    $filter: ModelMonthlyTenantProcessedTextCountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMonthlyTenantProcessedTextCounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        activatedStatus
        monthlyTextCount
        monthlyTextCountStartDate
        tenantId
        createdAt
        updatedAt
        monthlyTenantProcessedTextCountPlanIdPlanId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const monthlyTenantProcessedTextCountsByTenantId = /* GraphQL */ `
  query MonthlyTenantProcessedTextCountsByTenantId(
    $tenantId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMonthlyTenantProcessedTextCountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    monthlyTenantProcessedTextCountsByTenantId(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        activatedStatus
        monthlyTextCount
        monthlyTextCountStartDate
        tenantId
        createdAt
        updatedAt
        monthlyTenantProcessedTextCountPlanIdPlanId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMonthlyVoiceRecognitionUsage = /* GraphQL */ `
  query GetMonthlyVoiceRecognitionUsage(
    $userId: ID!
    $year: Int!
    $month: Int!
  ) {
    getMonthlyVoiceRecognitionUsage(
      userId: $userId
      year: $year
      month: $month
    ) {
      userId
      year
      month
      usageTime
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listMonthlyVoiceRecognitionUsages = /* GraphQL */ `
  query ListMonthlyVoiceRecognitionUsages(
    $userId: ID
    $yearMonth: ModelMonthlyVoiceRecognitionUsagePrimaryCompositeKeyConditionInput
    $filter: ModelMonthlyVoiceRecognitionUsageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMonthlyVoiceRecognitionUsages(
      userId: $userId
      yearMonth: $yearMonth
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        year
        month
        usageTime
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVoiceFileUploadRequest = /* GraphQL */ `
  query GetVoiceFileUploadRequest($id: ID!) {
    getVoiceFileUploadRequest(id: $id) {
      id
      sessionId
      filename
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVoiceFileUploadRequests = /* GraphQL */ `
  query ListVoiceFileUploadRequests(
    $filter: ModelVoiceFileUploadRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVoiceFileUploadRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sessionId
        filename
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomPromptMenu = /* GraphQL */ `
  query GetCustomPromptMenu($id: ID!) {
    getCustomPromptMenu(id: $id) {
      id
      tenantId
      sortOrder
      name
      promptId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomPromptMenus = /* GraphQL */ `
  query ListCustomPromptMenus(
    $filter: ModelCustomPromptMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomPromptMenus(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        sortOrder
        name
        promptId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomPrompt = /* GraphQL */ `
  query GetCustomPrompt($id: ID!) {
    getCustomPrompt(id: $id) {
      id
      summaryPromptSystemRole
      summaryPromptUserRole
      mergePromptSystemRole
      mergePromptUserRole
      mergePattern
      chunkSize
      chunkOverlap
      inputMaxLength
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomPrompts = /* GraphQL */ `
  query ListCustomPrompts(
    $filter: ModelCustomPromptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomPrompts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        summaryPromptSystemRole
        summaryPromptUserRole
        mergePromptSystemRole
        mergePromptUserRole
        mergePattern
        chunkSize
        chunkOverlap
        inputMaxLength
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAmiVoiceOneTimeAppKey = /* GraphQL */ `
  query GetAmiVoiceOneTimeAppKey($msg: String) {
    getAmiVoiceOneTimeAppKey(msg: $msg)
  }
`;
export const getResultFromUploadedFile = /* GraphQL */ `
  query GetResultFromUploadedFile($sessionId: String) {
    getResultFromUploadedFile(sessionId: $sessionId)
  }
`;
