import { API } from "@aws-amplify/api";
import { PubSub } from "@aws-amplify/pubsub";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Amplify } from "aws-amplify";
import CssBaseline from "@mui/material/CssBaseline";
import { Authenticator } from "@aws-amplify/ui-react";
import awsconfig from "./aws-exports";
import "./App.css";

import awsExports from "./aws-exports";

import { RequireAuth } from "./views/RequireAuth";
import Dashboard from "./views/Dashboard";
import UsageSituation from "./views/UsageSituation/UsageSituation";
import { SnackbarProvider } from "notistack";
import WordRegistration from "./views/WordRegistration/WordRegistration";
import RegisterNewWord from "./views/WordRegistration/RegisterNewWord";
import EditWord from "./views/WordRegistration/EditWord";
import AppContentWrapper from "./components/AppContentWrapper";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Login from "./views/Login/Login";
import SignUp from "./views/SignUp";
import VarificationCode from "./views/VarificationCode";
import PasswordReissue from "./views/PasswordReissue";
import CorporateProperWordList from "./views/WordRegistration/CorporateProperWordList";
import WithFeatureNotificationModal from "./components/WithFeatureNotificationModal";
import { TenantOptionsProvider } from "components/TenantOptionsProvider";
import FileUpload from "views/FileUpload";
import ProperNouns from "views/WordRegistration/ProperNouns";

Amplify.configure({
  ...awsExports,
  Auth: {
    cookieStorage: {
      // (optional) - Cookie path
      path: "/",
      // (optional) - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      sameSite: "strict",
      // (optional) - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: true,
    },
  },
});

API.configure(awsconfig);
PubSub.configure(awsconfig);

const urlParams = new URLSearchParams(window.location.search);
const sessionId = urlParams.get("sessionid");
if (sessionId) {
  sessionStorage.setItem("sessionId", sessionId);
}

// TODO: create color theme
const theme = createTheme({
  spacing: 4,
  palette: {
    primary: {
      main: "#67C6DD",
      contrastText: "#fff",
    },
    secondary: {
      main: "#9DC833",
      contrastText: "#fff",
    },
    monoqlo: {
      main: "#3C4A60",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: ["Noto Sans JP", "sans-serif"].join(","),
    subtitle1: {
      fontSize: 12,
      lineHeight: "16px",
      fontColor: "#1C222C",
      fontWeight: 600,
      leadingTrim: "both",
      textEdge: "cap",
    },
    subtitle2: {
      fontSize: 12,
      lineHeight: "16px",
      fontColor: "#5D5F5F",
      fontWeight: 300,
      leadingTrim: "both",
      textEdge: "cap",
    },
    body1: {
      fontSize: 14,
      fontColor: "#1C222C",
      fontWeight: 300,
    },
    body2: {
      fontSize: 14,
      fontColor: "#B7B7B7",
      fontWeight: 300,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        fontWeight: 600,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontWeight: 600,
          ...(ownerState.size === "large" && {
            height: "52px",
            fontSize: "18px",
          }),
          ...(ownerState.size === "medium" && {
            height: "44px",
            fontSize: "15px",
          }),
          ...(ownerState.size === "small" && {
            height: "44px",
            fontSize: "14px",
            lineHeight: "14px",
          }),
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        inputProps: {
          sx: {
            height: "36px",
            pt: "0px",
            pb: "0px",
          },
        },
      },
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          mt: "0px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          backgroundColor: "#fff",
          color: "#1C222C",
          borderBottom: "5px solid",
          borderImage: "linear-gradient(to right, #9dc832 0%, #67c6dd 100%)",
          borderImageSlice: 1,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        padding: {
          p: 0,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          lineHeight: "16px",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          height: "16px",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: "#3c4a68",
        },
      },
    },
  },
});

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <Authenticator.Provider>
            <TenantOptionsProvider>
              <WithFeatureNotificationModal>
                {" "}
                <BrowserRouter>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <RequireAuth>
                          <AppContentWrapper>
                            <Dashboard />
                          </AppContentWrapper>
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/proper-nouns"
                      element={
                        <RequireAuth>
                          <AppContentWrapper>
                            <ProperNouns />
                          </AppContentWrapper>
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/word-registration"
                      element={
                        <RequireAuth>
                          <AppContentWrapper>
                            <WordRegistration />
                          </AppContentWrapper>
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/register-new-word"
                      element={
                        <RequireAuth>
                          <AppContentWrapper>
                            <RegisterNewWord />
                          </AppContentWrapper>
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/edit-word"
                      element={
                        <RequireAuth>
                          <AppContentWrapper>
                            <EditWord />
                          </AppContentWrapper>
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/corporate-words"
                      element={
                        <RequireAuth>
                          <AppContentWrapper>
                            <CorporateProperWordList />
                          </AppContentWrapper>
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/usage-situation"
                      element={
                        <RequireAuth>
                          <AppContentWrapper>
                            <UsageSituation />
                          </AppContentWrapper>
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/file-upload"
                      element={
                        <RequireAuth>
                          <AppContentWrapper>
                            <FileUpload />
                          </AppContentWrapper>
                        </RequireAuth>
                      }
                    />
                    <Route path="/login" element={<Login />} />
                    <Route path="/sign-up" element={<SignUp />} />
                    <Route
                      path="/varification-code"
                      element={<VarificationCode />}
                    />
                    <Route
                      path="/password-reissue"
                      element={<PasswordReissue />}
                    />
                    <Route path="/terms_of_service.html" />
                    <Route path="/privacy_policy.html" />
                    <Route path="*" element={<Navigate to="/" />} />
                  </Routes>
                </BrowserRouter>
              </WithFeatureNotificationModal>
            </TenantOptionsProvider>
          </Authenticator.Provider>
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
