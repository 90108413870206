import { useState, useEffect } from "react";
import { getVoiceRecognitionAPIOneTimeAppKey } from "helper/voiceRecognitionHelper";
import useWordRegister from "hooks/useWordRegister";
import { Recorder } from "helper/recorder.js";
import { Wrp } from "helper/wrp.js";
import { Result } from "helper/result.js";
import MonthlyVoiceRecognitionUsageRepository from "repositories/MonthlyVoiceRecognitionUsageRepository";
import { useAuthenticator } from "@aws-amplify/ui-react";
import noSleepEnabler from "helper/noSleepEnabler";
import ProperNounsSettingRepository from "../repositories/ProperNounsSettingRepository";
// PRでコメントする。https://create-react-app.dev/docs/importing-a-component/#absolute-imports
import { properNounCategories } from "constants/properNounCategories";
import { languageModeOptions } from "constants/languageModeOptions";

window.Recorder = Recorder;

const useVoiceRecognition = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const monthlyVoiceRecognitionUsageRepository =
    new MonthlyVoiceRecognitionUsageRepository(); // FIX: コンポーネントごとにインスタンスを作成してしまう
  const [recognitionResultInProcess, setRecognitionResultInProcess] =
    useState("");
  const [recognitionFinalizedResult, setRecognitionFinalizedResult] =
    useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [isStartingRecord, setIsStartingRecord] = useState(false);
  // TODO: 初回リリースのダウンタイムを防ぐために課金体系の処理閉鎖
  // const [voiceInputUsageTime, setVoiceInputUsageTime] = useState(0);

  const {
    profiledWordsFileds,
    registerProfiledWords,
    registerProfiledWordsErrors,
    loadProfiledWords,
    addProfiledWords,
    updateProfiledWords,
    removeProfiledWords,
    convertListToString,
    saveProfiledWords,
    submitProfiledWords,
    bulkAddProfiledWords,
    loadCorporateProperNouns,
  } = useWordRegister();

  // Initialize the Wrp and Recorder libraries
  useEffect(() => {
    (async () => {
      // Define the event handlers and other functions here
      const connectStarted = () => {
        console.log("EVENT: connectStarted()");
      };

      const connectEnded = () => {
        console.log("EVENT: connectEnded()");
        setIsStartingRecord(false);
      };

      const disconnectStarted = () => {
        console.log("EVENT: disconnectStarted()");
      };

      const disconnectEnded = (event) => {
        console.log("EVENT: disconnectEnded()");
        saveMonthlyUsage(event.usageTime);
        // TODO: 初回リリースのダウンタイムを防ぐために課金体系の処理閉鎖
        // setVoiceInputUsageTime(event.usageTime);
        setIsRecording(false);
      };

      const feedDataResumeStarted = () => {
        console.log("EVENT: feedDataResumeStarted()");
        setIsRecording(true);
      };

      const feedDataResumeEnded = () => {
        console.log("EVENT: feedDataResumeEnded()");
      };

      const feedDataPauseStarted = () => {
        console.log("EVENT: feedDataPauseStarted()");
      };

      const feedDataPauseEnded = (reason) => {
        console.log(
          "EVENT: feedDataPauseEnded(): reason[code[" +
            reason.code +
            "] message[" +
            reason.message +
            "]]",
        );
      };

      const resultCreated = () => {
        setRecognitionResultInProcess("");
      };

      const resultUpdated = (result) => {
        result = Result.parse(result);
        setRecognitionResultInProcess(result.text || "");
      };

      const resultFinalized = (result) => {
        const parsedResult = Result.parse(result);

        // print each textList item
        const segmenterProperties = Wrp.getSegmenterProperties();
        const grammarFileNames = Wrp.getGrammarFileNames();
        let finalizedResult = [{ text: "", speakerLabel: "" }];
        if (segmenterProperties === "useDiarizer=1") {
          finalizedResult = convertToReulstWithDiarizer(parsedResult.results);
        } else if (grammarFileNames === "-a-general-en") {
          // ピリオドまたは、疑問符の後にスペースを追加する
          finalizedResult.text = parsedResult.text.replace(
            /([.?])(\S|$)/g,
            "$1 $2",
          );
        } else {
          finalizedResult.text = parsedResult.text;
        }

        setRecognitionFinalizedResult(finalizedResult || "");
        setRecognitionResultInProcess("");
      };

      const convertToReulstWithDiarizer = (results) => {
        let resultArray = [];
        let currentText = "";
        let currentLabel = "";
        let beforeLabel = "";

        // トークンごとにループを回す
        results[0].tokens.forEach((token, index) => {
          if (!currentLabel) {
            currentLabel = token.label;
            currentText = token.written;
            beforeLabel = token.label;
          } else if (token.label === undefined) {
            // ラベルがundefinedの場合、前回の話者ラベルを使用する
            // ex. 英語で音声入力した時のピリオドのラベルがundefinedになる
            currentLabel = beforeLabel;
            currentText += token.written;
          } else if (currentLabel && currentLabel !== token.label) {
            // 現在のラベルとトークンのラベルが異なる場合、
            // 現在のテキストとラベルをresultに追加し、resultArrayにpushする
            resultArray.push({ text: currentText, speakerLabel: currentLabel });

            // 新しいラベルとテキストで更新する
            currentText = token.written;
            currentLabel = token.label;
            beforeLabel = token.label;
          } else {
            const grammarFileNames = Wrp.getGrammarFileNames();
            if (grammarFileNames === "-a-general-en") {
              currentText += " ";
            }
            currentText += token.written;
          }

          // 最後のトークンの場合、テキストとラベルをresultに追加する
          if (index === results[0].tokens.length - 1) {
            resultArray.push({ text: currentText, speakerLabel: currentLabel });
          }
        });

        return resultArray; // 結果の配列を返す
      };

      // Initialize the Wrp and Recorder libraries
      Wrp.connectStarted = connectStarted;
      Wrp.connectEnded = connectEnded;
      Wrp.disconnectStarted = disconnectStarted;
      Wrp.disconnectEnded = disconnectEnded;
      Wrp.feedDataResumeStarted = feedDataResumeStarted;
      Wrp.feedDataResumeEnded = feedDataResumeEnded;
      Wrp.feedDataPauseStarted = feedDataPauseStarted;
      Wrp.feedDataPauseEnded = feedDataPauseEnded;
      Wrp.resultCreated = resultCreated;
      Wrp.resultUpdated = resultUpdated;
      Wrp.resultFinalized = resultFinalized;

      // Get the list of registered words before starting recording.
      await loadProfiledWords();
    })();
    return () => {
      stopRecording();
    };
  }, []);

  const stopRecording = () => {
    noSleepEnabler.disableNoSleep();
    if (Wrp.isActive()) {
      Wrp.feedDataPause();
    }
  };

  /**
   * Save when monthly usage does not exsit.
   * If it does update it.
   * Use Amplify api.
   * @param {*} userId
   * @param {*} year
   * @param {*} month
   * @param {*} usageTime
   */
  const saveMonthlyUsage = async (usageTime) => {
    const userId = user.username;
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    const monthlyUsage =
      await monthlyVoiceRecognitionUsageRepository.getMonthlyUsage(
        userId,
        year,
        month,
      );
    if (monthlyUsage) {
      return await monthlyVoiceRecognitionUsageRepository.updateMonthlyVoiceRecognitionUsage(
        userId,
        year,
        month,
        monthlyUsage.usageTime + usageTime,
      );
    } else {
      return await monthlyVoiceRecognitionUsageRepository.createMonthlyVoiceRecognitionUsage(
        userId,
        year,
        month,
        usageTime,
      );
    }
  };

  const convertCorporatePronounListToString = (corporatePronounList) => {
    return corporatePronounList
      .map((item) => `${item.writing.replace(/\u0020/g, "_")} ${item.reading}`)
      .join("|");
  };

  const startRecording = async (
    options = { useDiarizer: false, language: languageModeOptions.japanese },
  ) => {
    if (Wrp.isActive()) {
      stopRecording();
    }
    if (!Wrp.isActive()) {
      setIsStartingRecord(true);

      // 固有名詞設定を取得する
      const properNounSettings = ProperNounsSettingRepository.loadSettings();
      // properNounSettingsObj ex.{"marketing":{"isValid":true},"dev":{"isValid":true}}
      // properNounSettingsのisValidの値がtrueのもののみを配列で取得する
      const properNounSettingsArray = Object.keys(properNounSettings).filter(
        (key) => properNounSettings[key].isValid,
      );
      // TODO: カテゴリのソート順を反映する

      // Refresh registered words in Wrp.
      let pronounsStr = "";

      const corporateProperNouns = await loadCorporateProperNouns();
      // categoryIdがproperNounSettingsArrayに含まれるもののみにフィルターする
      const filteredCorporateProperNouns = corporateProperNouns.filter((item) =>
        properNounSettingsArray.includes(item.categoryId),
      );
      if (
        filteredCorporateProperNouns &&
        filteredCorporateProperNouns.length > 0
      ) {
        pronounsStr = convertCorporatePronounListToString(
          filteredCorporateProperNouns,
        );
      }

      let words = [];
      // 個人の固有名詞が適用中の場合のみ、個人の固有名詞を追加する
      if (properNounSettings[properNounCategories.personal.id]?.isValid) {
        words = await loadProfiledWords();
        if (words && words.length > 0) {
          const profiledWordsConverted = convertListToString(words);
          if (pronounsStr) {
            pronounsStr = `${pronounsStr}|${profiledWordsConverted}`;
          } else {
            pronounsStr = profiledWordsConverted;
          }
        }
      }

      Wrp.setProfileWords(pronounsStr);

      // Get the API key.
      const appKey = await getVoiceRecognitionAPIOneTimeAppKey();

      // Set the API key.
      Wrp.setAppKey(appKey);

      // Set Segmenter Properties
      if (options.useDiarizer) {
        Wrp.setSegmenterProperties("useDiarizer=1");
      } else {
        Wrp.setSegmenterProperties("useDiarizer=0");
      }

      // Set Language
      switch (options.language) {
        case languageModeOptions.english:
          Wrp.setGrammarFileNames("-a-general-en");
          break;
        case languageModeOptions.japanese:
          Wrp.setGrammarFileNames("-a-general");
          break;
        default:
          throw new Error("Invalid language mode");
      }

      // Start recording.
      Wrp.feedDataResume();
      noSleepEnabler.enableNoSleep();
    }
  };

  // Return the recognition result and any other required data or functions
  return {
    recognitionResultInProcess,
    recognitionFinalizedResult,
    isRecording,
    isStartingRecord,
    startRecording,
    stopRecording,
    profiledWordsFileds,
    registerProfiledWordsErrors,
    registerProfiledWords,
    addProfiledWords,
    updateProfiledWords,
    removeProfiledWords,
    saveProfiledWords,
    submitProfiledWords,
    bulkAddProfiledWords,
    // TODO: 初回リリースのダウンタイムを防ぐために課金体系の処理閉鎖
    // voiceInputUsageTime,
    // setVoiceInputUsageTime,
  };
};

export default useVoiceRecognition;
