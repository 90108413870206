import { useEffect } from "react";
import Box from "@mui/material/Box";
import useWordRegister from "../../hooks/useWordRegister";
import { Button, Grid, List, ListItem, Typography } from "@mui/material";
import ProperNounListHeader from "./ProperNounListHeader";
import InfomationMessage from "../../components/InfomationMessage";
import { useTheme } from "@mui/material/styles";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import ProperNounsSettingRepository from "../../repositories/ProperNounsSettingRepository";
import { useState } from "react";

export default function CorporateProperWordList() {
  const theme = useTheme();
  let [searchParams] = useSearchParams();
  const [isChecked, setIsChecked] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const { loadCorporateProperNouns, corporateProperNouns } = useWordRegister();
  const handleChangeSwitch = (event) => {
    ProperNounsSettingRepository.updateSettings(
      searchParams.get("categoryId"),
      event.target.checked,
    );
    setIsChecked(event.target.checked);
  };
  const hasCategory = corporateProperNouns.some(
    (item) => item.categoryId === searchParams.get("categoryId"),
  );

  useEffect(() => {
    (async () => {
      if (!location.state || !location.state.displayName) {
        navigate("/proper-nouns");
        return;
      }
      await loadCorporateProperNouns();
      const settings = await ProperNounsSettingRepository.loadSettings();
      if (settings[searchParams.get("categoryId")]) {
        setIsChecked(settings[searchParams.get("categoryId")].isValid);
      }
    })();
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          mt: { xs: theme.spacing(5), md: theme.spacing(10) },
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            width: { md: "640px" },
            marginX: { md: "auto" },
          }}
        >
          <ProperNounListHeader
            title={location.state?.displayName}
            message="組織の固有名詞は編集ができません。変更が必要な場合、管理者へお問い合わせください。"
            showSwitch
            onChangeSwitch={handleChangeSwitch}
            isChecked={isChecked}
          />
          <Grid item>
            <List sx={{ padding: "0" }}>
              {!hasCategory && (
                <ListItem>
                  <InfomationMessage message="登録されている組織の固有名詞はありません。" />
                </ListItem>
              )}
              {corporateProperNouns.map(
                (noun) =>
                  // Check if the category matches the searchParams
                  noun.categoryId === searchParams.get("categoryId") && (
                    <Box key={noun.id}>
                      <ListItem
                        divider
                        sx={{
                          padding: "0.875rem 0.75rem",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="body1">{noun.writing}</Typography>
                        <Typography variant="body1" color="text.secondary">
                          {noun.reading}
                        </Typography>
                      </ListItem>
                    </Box>
                  ),
              )}
            </List>
          </Grid>
          <Button
            id="goBackToProperNounList"
            variant="text"
            size="small"
            sx={{ mt: "1.25rem", height: "0.875rem", color: "primary" }}
            onClick={() => navigate("/proper-nouns")}
          >
            戻る
          </Button>
        </Box>
      </Box>
    </>
  );
}
