import * as React from "react";
import { Button, Stack } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useContext } from "react";
import { TenantOptionsContext } from "./TenantOptionsProvider";

const headerItemStyle = {
  fontSize: "12px",
  color: "#1c222c",
  fontWeight: "300",
  mr: "0.875rem",
  paddingX: 0,
  width: "auto",
};

export const MainListHeaderItems = () => {
  const { tenantOptions } = useContext(TenantOptionsContext);
  return (
    <React.Fragment>
      <Stack direction="row">
        <Button
          id="homeInputReportPc"
          variant="text"
          type="small"
          component={RouterLink}
          to="/"
          sx={{ ...headerItemStyle }}
        >
          報告入力
        </Button>
        <Button
          id="properNounsPc"
          variant="text"
          type="small"
          component={RouterLink}
          to="/proper-nouns"
          sx={{ ...headerItemStyle }}
        >
          固有名詞
        </Button>
        {/* TODO: 個社特別対応 */}
        {/* <Button
          id="usageSituationPc"
          variant="text"
          type="small"
          component={RouterLink}
          to="/usage-situation"
          sx={{ ...headerItemStyle }}
        >
          利用状況
        </Button> */}
        {tenantOptions?.file_upload_enabled && (
          <Button
            id="fileUploadPc"
            variant="text"
            type="small"
            component={RouterLink}
            to="/file-upload"
            sx={{ ...headerItemStyle }}
          >
            ファイルアップロード
          </Button>
        )}
      </Stack>
    </React.Fragment>
  );
};
